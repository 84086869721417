import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://95372bc36792415c930102ba2d6b3f90@o315385.ingest.sentry.io/4504965855117312",
  debug: false,
  environment: "preview",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});